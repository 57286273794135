.header {
  justify-content: space-between;
  max-width: 1352px;
  margin: auto;
  padding: 16px;
}

.navToggle {
  position: absolute;
  top: 15px;
  right: 16px;
  z-index: 100;
  width: 25px;
}

.nav {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  justify-content: center;
}

.nav::after {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: black;
  opacity: 0.9;
}

.nav li {
  border-bottom: 1px solid transparent;
}

.nav li:hover {
  border-bottom: 1px solid var(--clrGreen);
}

@media (min-width: 550px) {
  .nav {
    display: flex;
    width: 210px;
    justify-content: space-between;
    flex-direction: row;
    gap: 32px;
  }

  .nav,
  .nav::after {
    position: relative;
  }

  .navToggle {
    display: none;
  }
}

@media (min-width: 900px) {
  .header {
    padding: 24px 60px;
  }
}
