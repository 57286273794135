.sources {
  gap: 25px;
}

.sourceLink {
  gap: 5px;
  border-bottom: 2px solid var(--clrGreen);
  width: fit-content;
}

.sourceLink:hover {
  color: #9bb436;
  border-bottom: 2px solid #9bb436;
}

.sourceLink img {
  width: 22px;
  height: 22px;
}

.greenButton {
  height: 48px;
  padding: 0px 5px 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
}

.greenButton:hover {
  background-color: #9bb436;
}

.grayButton {
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.grayButton:hover {
  background-color: #484848;
}

@media (min-width: 1000px) {
  .greenButton {
    padding: 3px 12px 3px 23px;
    height: 54px;
  }

  .grayButton {
    height: 54px;
    width: 54px;
  }
}
