.profile {
  flex-direction: column;
  margin-block: 40px 64px;
  gap: 64px;
}

.profileContent {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.profileContent h1 {
  margin-bottom: 12px;
}

.profileButtons {
  gap: 9px;
}

.profilePicture img {
  width: 100%;
  border-radius: 16px;
}

@media (min-width: 700px) {
  .profile {
    flex-direction: row;
    margin-block: 40px 80px;
  }
}

@media (min-width: 1000px) {
  .profileContent {
    max-width: 544px;
    gap: 40px;
  }

  .profilePicture > img {
    max-width: 600px;
  }
}
