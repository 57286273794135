.about {
  padding-block: 64px;
  flex-direction: column;
  align-items: flex-start;
}

.border {
  border-top: 1px solid var(--clrGray);
}

.aboutContent {
  flex-direction: column;
  align-items: flex-start;
}

.aboutDescription {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 64px;
}

.aboutDescription p {
  margin-block: 24px;
}

.aboutImage img {
  width: 100%;
  border-radius: 8px;
}

@media (min-width: 700px) {
  .about {
    padding-block: 80px;
  }
  .aboutContent {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .aboutDescription {
    max-width: 400px;
  }

  .aboutImage {
    background-color: var(--clrCream);
    max-height: 500px;
    width: 100%;
    justify-content: center;
    border-radius: 16px;
  }

  .aboutImage img {
    max-height: 500px;
    width: auto;
    margin: auto;
  }
}

@media (min-width: 975px) {
  .aboutDescription {
    max-width: 700px;
  }

  .singlePageAbout {
    max-width: 600px;
  }

  .aboutImage {
    margin-top: 64px;
    max-height: 700px;
  }

  .aboutImage img {
    max-height: 700px;
  }
}

@media (min-width: 1050px) {
  .singlePageAbout {
    max-width: 700px;
  }
}
