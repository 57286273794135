.connect {
  flex-direction: column;
  border-top: 1px solid var(--clrGray);
  padding-block: 64px;
  align-items: start;
}

.connectContent {
  flex-direction: column;
  margin-bottom: 64px;
  gap: 8px;
}

.connectSources {
  margin-top: 40px;
  gap: 30px;
}

.connectSources img {
  width: 32px;
  height: 32px;
}

.greenUnderline {
  text-underline-offset: 5px;
  text-decoration: underline;
  text-decoration-color: var(--clrGreen);
}

.greenUnderline:hover {
  color: var(--clrGreen);
}

.connectForm {
  flex-direction: column;
  align-items: start;
  width: 100%;
  position: relative;
}

.connectForm input,
.connectForm textarea {
  width: 100%;
  border: none;
  padding: 10px 16px;
  margin-block: 8px 24px;
  border-radius: 4px;
}

.connectForm textarea {
  resize: none;
  height: 150px;
}

.connectForm .submitButton {
  padding: 16px 40px;
  border-radius: 100px;
}

.connectForm .submitButton:hover {
  background-color: #9bb436;
}

.copyright {
  margin-top: 80px;
}

.successModal {
  position: absolute;
  top: 0;
  right: 0;
  width: 250px;
  height: 75px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  background-color: var(--clrWhite);
}

.closeModal {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
}

@media (min-width: 700px) {
  .connect {
    flex-direction: row;
    align-items: stretch;
    gap: 30px;
    justify-content: space-between;
    padding-block: 80px;
  }

  .copyright {
    display: none;
  }

  .connectContent {
    justify-content: space-between;
    align-items: flex-start;
  }

  .connectContent .copyright {
    display: block;
  }

  .connectForm {
    max-width: 400px;
  }

  .connectForm input,
  .connectForm textarea {
    padding: 12px 16px;
  }
}

@media (min-width: 1000px) {
  .connectForm {
    max-width: 600px;
  }

  .connectContent {
    max-width: 600px;
  }
}
