.experience {
  border-top: 1px solid var(--clrDarkGray);
  flex-direction: column;
  align-items: flex-start;
  padding-block: 64px;
}

.experiencesHolder {
  flex-direction: column;
  gap: 40px;
}

.experienceCard {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.experiencesMain {
  flex-direction: column;
  align-items: flex-start;
}

.experienceDate {
  margin-block: 4px;
}

.experienceCompany {
  margin-bottom: 12px;
}

@media (min-width: 700px) {
  .experience {
    padding-block: 80px;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
  }

  .experiencesHolder {
    max-width: 390px;
  }

  .experiencesMain {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

@media (min-width: 1000px) {
  .experiencesHolder {
    max-width: 600px;
  }
}
