.projects {
  border-top: 1px solid var(--clrGray);
  padding-block: 64px;
}

.projectInfo {
  margin-bottom: 64px;
}

.projectsHolder {
  flex-direction: column;
  margin-top: 64px;
  gap: 96px;
}

.projectCard {
  flex-direction: column;
  gap: 40px;
}

.projectImage {
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 60px 28px;
}

.projectImage img {
  width: 100%;
  border-radius: 8px;
}
.projectContent {
  flex-direction: column;
  align-items: stretch;
}

.projectContent p {
  margin-block: 16px 16px;
}

.projectInfo {
  margin-bottom: 40px;
}

.projectInfo ul li {
  width: 100%;
  justify-content: space-between;
  padding-block: 16px;
  border-bottom: 1px solid var(--clrGray);
}

@media (min-width: 700px) {
  .projectCard {
    flex-direction: row;
    justify-content: space-between;
  }

  .projectImage {
    max-width: 350px;
    max-height: 350px;
  }

  .projectContent {
    max-width: 300px;
  }
}

@media (min-width: 900px) {
  .projectImage {
    max-width: 400px;
    max-height: 400px;
  }

  .projectContent {
    max-width: 500px;
  }

  .projectIntro {
    max-width: 500px;
  }
}

@media (min-width: 1000px) {
  .projectImage {
    max-width: 600px;
    max-height: 600px;
    padding: 126px 57px;
  }
}
