:root {
  /* Font Families */
  --ffBebas: "Bebas Neue", sans-serif;
  --ffManrope: "Manrope", sans-serif;
  --ffInter: "Inter", sans-serif;

  /* Font Sizes */
  --fsXXL: clamp(3.125rem, 2.2143rem + 4.5536vw, 6.3125rem);
  --fsXL: clamp(2.6875rem, 1.6563rem + 3.4375vw, 4.75rem);
  --fsL: clamp(1.5rem, 1.3571rem + 0.7143vw, 2rem);
  --fsM: clamp(1rem, 0.9375rem + 0.2083vw, 1.125rem);
  --fsS: 16px;
  --fsXS: clamp(0.875rem, 0.8125rem + 0.2083vw, 1rem);

  /* Colors */
  --clrBlack: #0a0a0a;
  --clrDarkGray: #1a1a1a;
  --clrGray: #484848;
  --clrCream: #c7c7c7;
  --clrWhite: #ffffff;
  --clrGreen: #d3e97a;

  /* Line Height */
  --lineHeightM: 150%;
  --lineHeightS: 90%;

  /* Letter Spacing */
  --letterSpacingM: -0.48px;
  --letterSpacingS: -0.32px;
}

/* ========================== */
/* ========= RESET ========== */
/* ========================== */

/* https://andy-bell.co.uk/a-modern-css-reset/ */

/* ----- Box sizing ----- */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

/* ----- Set up the body ----- */
body {
  min-height: 100vh;
  background-color: var(--clrBlack);
  font-family: var(--ffManrope);
  font-size: var(--fsS);
  color: var(--clrCream);
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

/* ----- Make form elements easier to work with ----- */
input,
button,
textarea {
  font: inherit;
}

/* ----- Remove animations for people who have turned them off ----- */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* =========================== */
/* ===== Utility classes ===== */
/* =========================== */

/* ----- General ----- */

.flex {
  display: flex;
  align-items: center;
}

.container {
  max-width: 1256px;
  padding-inline: 16px;
  margin: auto;
}

@media (min-width: 500px) {
  .container {
    padding-inline: 50px;
  }
}

@media (min-width: 700px) {
  .container {
    padding-inline: 19px;
  }
}

@media (min-width: 900px) {
  .container {
    padding-inline: 40px;
  }
}

.bold {
  font-weight: 700;
}

.medium {
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

.pointer {
  cursor: pointer;
}

.transition {
  transition: all 0.25s;
}

.sectionTitle {
  margin-bottom: 12px;
}

.hidden {
  display: none;
}

/* ----- Colors ----- */

.textBlack {
  color: var(--clrBlack);
}

.textCream {
  color: var(--clrCream);
}

.textWhite {
  color: var(--clrWhite);
}

.textGreen {
  color: var(--clrGreen);
}

.bgBlack {
  background-color: var(--clrBlack);
}

.bgDarkGray {
  background-color: var(--clrDarkGray);
}

.bgGreen {
  background-color: var(--clrGreen);
}

/* ----- Typography ----- */

.ffBebas {
  font-family: var(--ffBebas);
}

.ffManrope {
  font-family: var(--ffManrope);
}

.ffInter {
  font-family: var(--ffInter);
}

.fsXXL {
  font-size: var(--fsXXL);
}

.fsXL {
  font-size: var(--fsXL);
}

.fsL {
  font-size: var(--fsL);
}

.fsM {
  font-size: var(--fsM);
}

.fsS {
  font-size: var(--fsS);
}

.fsXS {
  font-size: var(--fsXS);
}

.lineHeightS {
  line-height: var(--lineHeightS);
}

.lineHeightM {
  line-height: var(--lineHeightM);
}

.letterSpacingS {
  letter-spacing: var(--letterSpacingS);
}

.letterSpacingM {
  letter-spacing: var(--letterSpacingM);
}
