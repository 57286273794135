.capabilities {
  flex-direction: column;
  align-items: start;
  padding-block: 64px;
  border-top: 1px solid var(--clrDarkGray);
}

.buttonsHolder {
  margin-top: 24px;
  flex-wrap: wrap;
  gap: 12px;
}

.capabilitiesButton {
  padding: 9px 25px;
  border: 1px solid var(--clrGray);
  border-radius: 100px;
}

.capabilitiesButton:hover {
  background-color: var(--clrWhite);
  color: var(--clrBlack);
}

@media (min-width: 700px) {
  .capabilities {
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
    padding-block: 80px;
  }

  .capabilitiesContent {
    max-width: 390px;
  }

  .capabilities p {
    margin-bottom: 40px;
  }

  .buttonsHolder {
    gap: 16px;
  }
}

@media (min-width: 1000px) {
  .capabilitiesContent {
    max-width: 600px;
  }
}
